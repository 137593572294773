import React, { useState } from "react";

import SectionContainer from "../SectionContainer";
import classNames from "classnames";

import css from "./SectionClientForm.module.css";

const accordionContent = {
    "open": "Build Marketplace",
    "close": "Collapse"
}

const SectionClientForm = props => {
    const [accordion, setAccordion] = useState(false);
    return (
        <SectionContainer
            id={props.sectionId}
            className={props.className}
            rootClassName={classNames(props.rootClassName || css.root)}
            appearance={props.appearance}
            options={props.fieldOptions}
        >

            <button
                className={`${css.accordion} ${props.defaultClasses.ctaButton}`}
                onClick={() => setAccordion(!accordion)}
            >
                {accordion ? accordionContent.close : accordionContent.open}
            </button>
            <div className={`${css.panel} ${accordion ? css.show : ""}`}>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeKd9-VIRHuUNnIYmAHklod1ODU1cj2CcRXQTHowcjGoz42Uw/viewform?embedded=true"
                    width="640"
                    height="434"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    className={css.iframe}
                    // scrolling="no"
                >
                    Loading…
                </iframe>
            </div>

        </SectionContainer>
    )
}

export default SectionClientForm;